export const API_HOST = process.env.REACT_APP_API_HOST;

export const PRODUCT_HOST = process.env.REACT_APP_PRODUCT_HOST;

const getUrl = (resource: string) => `${API_HOST}/v1_api${resource}`;

const getUrlV2 = (resource: string) => `${API_HOST}/v2_api${resource}`;

const getUrlPrivate = (resource: string) =>
  `${API_HOST}/v1_api_private${resource}`;

export const apiRoutesMap = {
  makeReserve: {
    getRoute: (id: string | number) =>
      getUrlPrivate(`/zakazes/${id}/make-reserve`),
  },
  articles: {
    getRoute: () => getUrlPrivate(`/articles`),
  },
  article: {
    getRoute: (id: string | number) => getUrlPrivate(`/articles/${id}`),
  },
  users: {
    getRoute: () => getUrlPrivate(`/users`),
  },
  user: {
    getRoute: (id: string | number) => getUrlPrivate(`/users/${id}`),
  },
  realizationDocumentsPrint: {
    getRoute: (guid: string | number) =>
      getUrl(`/realization-documents/${guid}/print-kind`),
  },
  zakazesDocumentsPrint: {
    getRoute: (guid: string | number) =>
      getUrl(`/zakazes-documents/${guid}/print-kind`),
  },
  callTasks: {
    getRoute: () => getUrlPrivate(`/tasks`),
  },
  callTask: {
    getRoute: (id: string | number) => getUrlPrivate(`/tasks/${id}`),
  },
  phoneCallRecord: {
    getRoute: (id: string | number) => getUrlPrivate(`/phonecall/${id}/record`),
  },
  reportsList: {
    getRoute: () => getUrlPrivate(`/reports`),
  },
  categories: {
    getRoute: () => getUrlPrivate(`/categories`),
  },
  category: {
    getRoute: (id: string | number) => getUrlPrivate(`/categories/${id}`),
  },
  categoriesRefresh: {
    getRoute: () => getUrlPrivate(`/categories/refresh`),
  },
  susOrders: {
    getRoute: () => getUrlPrivate(`/sus_orders`),
  },
  order: {
    getRoute: (id: string | number) => getUrlPrivate(`/orders/${id}`),
  },
  contractorUser: {
    getRoute: (contractorId: string | number, userId: string | number) =>
      getUrlPrivate(`/contractors/${contractorId}/users/${userId}`),
  },
};

export const apiPublicRoutesMap = {
  categories: {
    getRoute: () => getUrl(`/categories`),
  },
  searchProductsByBarcodes: {
    getRoute: () => getUrl(`/products/search`),
  },
  smartTask: {
    getRoute: () => getUrl(`/promotions/smart`),
  },
  categoriesMissingProducts: {
    getRoute: () => getUrl(`/categories/missing_products`),
  },
};
